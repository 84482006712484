import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DIGITAL_UI } from "../theme";
import myprov_logo_new_colors from "../assets/images/php_myprov_logo.png";
import { AppBar, Link, Button } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";

import config from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    background: DIGITAL_UI.header || `#FFFFFF`,
    height: 98,
    position: "fixed",
    padding: [[0, 40]],
    "@media only screen and (max-width: 650px)": {
      display: "flex",
      flexFlow: "column",
      alignItems: "flex-end",
      padding: [[0, 10]],
    },
  },
  logo: {
    background: `url(${myprov_logo_new_colors})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "left center",
    cursor: "pointer",
    height: 65,
    width: 470,
    "@media only screen and (max-width: 650px)": {
      width: "100%",
    },
  },
  button: {
    "@media only screen and (max-width: 650px)": {
      height: 35,
      width: "fit-content",
    },
  },
}));

const Masthead = () => {
  const classes = useStyles();
  const { isAuthenticated, logout } = useAuth0();

  return (
    <AppBar
      className={classes.root}
      id="masthead"
      position="static"
      elevation={3}
      square
    >
      {/* <Link className={classes.logo} variant="inherit" color={'inherit'} target='_blank' rel='noreferrer' href='#' aria-label='consumer logo'> </Link> */}
      <Link
        className={classes.logo}
        variant="inherit"
        color={"inherit"}
        rel="noreferrer"
        href="/profile"
        aria-label="consumer logo"
      >
        {" "}
      </Link>
      {isAuthenticated && (
        <Button
          variant="contained"
          className={classes.button}
          onClick={() =>
            logout({ logoutParams: { returnTo: config.myProvSamlUrl } })
          }
        >
          Log out
        </Button>
      )}
    </AppBar>
  );
};

export default Masthead;
