import config from '../config'
import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from "@auth0/auth0-react"
// import { _isBrowserBraveOrSafari, _cacheLocation } from '../utils'
// import history from '../utils/history'

const Auth0ProviderWithHistory = ({children}) => {
  const navigate = useNavigate()

  // const [isBrowserBraveOrSafari, setIsBrowserBraveOrSafari] = useState(() => _isBrowserBraveOrSafari())
  // const [cacheLocation__, setCacheLocation__] = useState(() => _cacheLocation())
  // const BASEHREF = '/'  

  const onRedirectCallback = (appState) => {
    // console.log('appstate: ', appState)
    navigate(appState?.returnTo || window.location.pathname)
  };  

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}      
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: config.auth0AudienceIdentifier,
        redirect_uri: window.location.origin,        
        useRefreshTokens:true,
        cacheLocation:'localstorage',
        scope:'openid profile email offline_access'
      }}
      >
      {children} 
    </Auth0Provider>
  )
};

export default Auth0ProviderWithHistory